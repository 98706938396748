import React from 'react';
import styled from 'styled-components';
import Markdown from '../commons/Markdown';
import theme from '../../constants/theme';
import Container from '../layout/Container';
import Alink from '../animations/Alink';
import ResponsiveText from '../typography/ResponsiveText';

const Wrapper = styled.section`
  margin-top: 60px;
  margin-bottom: 60px;

  /* h2 {
    font-size: 64px;
    font-weight: 500;
    line-height: 74px;
    text-transform: unset;
    font-family: ${theme.fontNeue};
    margin-bottom: 30px;
  } */

  h2 {
    margin-bottom: 40px;
  }

  .container {
    flex-wrap: wrap;
  }

  .markdown {
    font-size: 21px;
    a:link,
    a:visited {
      text-decoration: underline;
    }
    h3 {
      font-family: ${theme.fontNeue};
      font-size: 64px;
      line-height: 74px;
      text-transform: none;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 30px;
    margin-bottom: 30px;

    h2 {
      margin-bottom: 20px;
    }

    .markdown {
      h3 {
        font-size: 32px;
        line-height: 45px;
      }
    }
  }
`;

export default function SectionTitleAndDescription({
  anchor,
  title,
  description,
  labelCta,
  linkCta,
  generatedId,
}) {
  return (
    <Wrapper
      data-scroll-section
      data-scroll
      data-scroll-id={'titleAndDescriptionSection_' + generatedId}
      id={anchor}
    >
      <Container>
        {title && (
          <ResponsiveText type="bigger">
            <h2>{title}</h2>
          </ResponsiveText>
        )}
        {description && <Markdown content={description} />}
        {labelCta && linkCta && (
          <p style={{ marginTop: '20px' }}>
            <Alink href={linkCta}>{labelCta}</Alink>
          </p>
        )}
      </Container>
    </Wrapper>
  );
}
