import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../components/commons/Layout';
import SectionTitleAndDescription from '../../components/sections/SectionTitleAndDescription';

export default function NormalPage({
  data: {
    backend: { page },
  },
  pageContext: { seoRules },
}) {
  const { title, description } = page;

  return (
    <Layout seo={seoRules && seoRules[0] && seoRules[0].seo}>
      <SectionTitleAndDescription title={title} description={description} />
    </Layout>
  );
}

export const query = graphql`
  query ($id: ID!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    backend {
      page(id: $id) {
        title
        description
      }
    }
  }
`;
